import "./App.css";
import { api } from "./api/client";
import { useEffect, useState } from "react";
import { DrawCities } from "./DrawCities";

function App() {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getAllCities = async () => {
      const res = await api.get("/cities?withPolygon=true&withEncryption=false&noCache=true");
      // const { results } = decrypt(res.data);
      console.log(res.data.results);
      setCities(res.data.results); 
    };

    getAllCities();
  }, []); // Adding an empty dependency array because we only want to call getAllCities once

  function decrypt(data) {
    const key = 'P&aJAf33B3SfC8Rog8K#aHPBGcbFahGR';
    const keyBytes = new TextEncoder().encode(key);
    const dataBytes = new TextEncoder().encode(data);
    const encryptedBytes = new Uint8Array(dataBytes.length);
    for (let i = 0; i < dataBytes.length; i++) {
      encryptedBytes[i] = dataBytes[i] ^ keyBytes[i % keyBytes.length];
    }
    let x = new TextDecoder().decode(encryptedBytes);
    return JSON.parse(base64ToUtf8(x));
  }

  function base64ToUtf8(base64String) {
    // Decode Base64 to binary data
    let binaryData = window.atob(base64String);
  
    // Create a Uint8Array from the binary data
    let uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
  
    // Convert the Uint8Array to a UTF-8 string
    let utf8String = new TextDecoder('utf-8').decode(uint8Array);
  
    return utf8String;
  }

  return <DrawCities cities={cities} />;
}

export default App;
