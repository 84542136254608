import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon, Popup } from 'react-leaflet';

export function DrawCities({ cities }) {
  const center = [33.9513343, 43.8207402];
  const [unValidItems, setUnValidItems] = useState({ districts: [], subDistricts: [] });
  const [tabIndex, setTabIndex] = useState(0);
  const [hideList, setHideList] = useState([]);
  const [hideSubList, setSubHideList] = useState([]);
  const [hideCities, setHideCities] = useState(false);
  const [hideDistricts, setHideDistricts] = useState(false);
  const [hideSubDistricts, setHideSubDistricts] = useState(false);
  const purpleOptions = { color: 'purple' };
  const redOptions = { color: 'red' };
  const blueOptions = { color: 'navy' };

  useEffect(() => {
    if (tabIndex === 0) {
      setUnValidItems({
        districts: [],
        subDistricts: [],
      });
    }
  }, [tabIndex]);

  const handleHideItem = (itemId) => {
    setHideList([...hideList, itemId]);
  };

  const handleUnhideItem = (itemId) => {
    setHideList(hideList.filter(id => id !== itemId));
  };

  const handleHideSubItem = (itemId) => {
    setSubHideList([...hideSubList, itemId]);
  };

  const handleUnhideSubItem = (itemId) => {
    setSubHideList(hideSubList.filter(id => id !== itemId));
  };

  const navItems = [
    'Map',
    'Districts with no polygon',
    'SubDistricts with no polygon',
    'Hidden Districts',
    'Hidden SubDistricts',
  ];

  return (
    <>
      <ul className="nav nav-tabs">
        {navItems.map((navItem, index) => {
          return (
            <li key={index} className="nav-item" onClick={() => setTabIndex(index)}>
              <a
                className={`nav-link ${index === tabIndex ? 'active' : ''} `}
                href="#"
              >
                {navItem}
              </a>
            </li>
          );
        })}
      </ul>

      {tabIndex === 0 ? (
        <MapContainer
          center={center}
          zoom={8}
          style={{
            height: '100vh',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '8vh',
              left: '1vw',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              padding: '10px',
              borderRadius: '5px',
              zIndex: 1000,
            }}
          >
            <div>
              <input
                type="checkbox"
                checked={hideCities}
                onChange={() => setHideCities(!hideCities)}
              />
              <label>Hide Cities</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={hideDistricts}
                onChange={() => setHideDistricts(!hideDistricts)}
              />
              <label>Hide Districts</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={hideSubDistricts}
                onChange={() => setHideSubDistricts(!hideSubDistricts)}
              />
              <label>Hide Sub-Districts</label>
            </div>
          </div>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {
            console.log('cities: ',cities)
          }
          {cities.map((city) => {
            if (!hideCities) {
              const reshapedPolygon = city.polygon.map((polyPair) => [
                polyPair[1],
                polyPair[0],
              ]);
              return (
                <Polygon
                  key={city.id}
                  pathOptions={redOptions}
                  positions={reshapedPolygon}
                />
              );
            }
          })}
          {cities.map((city) => {
            return city.districts.map((district) => {
              if (
                !hideDistricts &&
                district.polygon &&
                !hideList.includes(district.id)
              ) {
                const reshapedPolygon = district.polygon.map((polyPair) => [
                  polyPair[1],
                  polyPair[0],
                ]);

                return (
                  <Polygon
                    key={district.id}
                    pathOptions={purpleOptions}
                    positions={reshapedPolygon}
                  >
                    <Popup>
                      <div className="h3">{district.name}</div>
                      <button
                        className="btn btn-warning"
                        onClick={() => handleHideItem(district.id)}
                      >
                        Hide
                      </button>
                    </Popup>
                  </Polygon>
                );
              }

              unValidItems.districts.push(district);
            });
          })}
          {cities.map((city) => {
            return city.districts.map((district) => {
              return district.subDistricts.map((subDistrict) => {
                if (
                  !hideSubDistricts &&
                  subDistrict.polygon &&
                  !hideSubList.includes(subDistrict.id)
                ) {
                  const reshapedPolygon = subDistrict.polygon.map(
                    (polyPair) => [polyPair[1], polyPair[0]]
                  );

                  return (
                    <Polygon
                      key={subDistrict.id}
                      pathOptions={blueOptions}
                      positions={reshapedPolygon}
                    >
                      <Popup>
                        <div className="h3">{subDistrict.name}</div>
                        <button
                          className="btn btn-warning"
                          onClick={() => handleHideSubItem(subDistrict.id)}
                        >
                          Hide
                        </button>
                      </Popup>
                    </Polygon>
                  );
                }
                unValidItems.subDistricts.push(subDistrict);
              });
            });
          })}
        </MapContainer>
      ) : (
        <div className="container pt-5">
          {tabIndex === 1 ? (
            <div>
              {unValidItems.districts.map((district) => (
                <div key={district.id} className="alert alert-danger" role="alert">
                  {district.name}
                </div>
              ))}
            </div>
          ) : tabIndex === 2 ? (
            <div>
              {unValidItems.subDistricts.map((subDistrict) => (
                <div key={subDistrict.id} className="alert alert-danger" role="alert">
                  {subDistrict.name}
                </div>
              ))}
            </div>
          ) : tabIndex === 3 ? (
            <div>
              {cities.map((city) => (
                city.districts.map((district) => {
                  
                  if (hideList.includes(district.id)) {
                    return (
                      <div key={district.id} className="alert alert-danger" role="alert">
                        {district.name}{'  '}
                        <button
                          className="btn btn-warning"
                          onClick={() => handleUnhideItem(district.id)}
                        >
                          Unhide
                        </button>
                      </div>
                    );
                  }
                })
              ))}
            </div>
          ) : (
            <div>
              {cities.map((city) => (
                city.districts.map((district) => {return district.subDistricts.map((subDistrict) => {
                  if (hideSubList.includes(subDistrict.id)) {
                    return (
                      <div key={subDistrict.id} className="alert alert-danger" role="alert">
                        {subDistrict.name}{'  '}
                        <button
                          className="btn btn-warning" 
                          onClick={() => handleUnhideSubItem(subDistrict.id)}
                        >
                          Unhide
                        </button>
                      </div>
                    );
                  }
                })})
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}
